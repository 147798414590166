<template>
  <div>
    <template v-if="!dossiers || dossiers.length != 1">
      Keine Dossiers unter diesem Link verfügbar
    </template>
    <template v-else>
      <DossierView :dossier-id="dossiers[0].id" />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { useDossierStore } from "@/common/store/dossier"
import { storeToRefs } from "pinia"
import DossierView from "@/common/views/DossierView/index.vue"
import { onMounted } from "vue"

const { dossiers } = storeToRefs(useDossierStore())
onMounted(useDossierStore().fetchDossiers)
</script>

<style></style>
