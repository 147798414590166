// @ts-ignore
const config = {
  backend: {
    api:
      // @ts-ignore
      dynamicConfig?.API_BASE_URL ??
      import.meta.env.VITE_API_BASE_URL ??
      window.location.origin + "/api/v1/",
  },
  agent: {
    weasyprint: {
      url:
        // @ts-ignore
        dynamicConfig?.AGENT_WEASYPRINT_URL ??
        import.meta.env.VITE_AGENT_WEASYPRINT_URL ??
        "http://localhost:5000/",
    },
  },
  // @ts-ignore
  fohUrl:
    // @ts-ignore
    dynamicConfig?.FOH_BASE_URL ??
    import.meta.env.VITE_FOH_BASE_URL ??
    "https://dossier.direct",
  // @ts-ignore
  rootOfTrust: JSON.parse(
    // @ts-ignore
    dynamicConfig?.ROT ?? import.meta.env.VITE_ROT ?? "null",
  ),
  sentry: {
    dsn:
      // @ts-ignore
      dynamicConfig?.SENTRY_DSN ?? import.meta.env.VITE_SENTRY_DSN ?? null,
    environment:
      // @ts-ignore
      dynamicConfig?.SENTRY_ENVIRONMENT ??
      import.meta.env.VITE_SENTRY_ENVIRONMENT ??
      "dev",
    release:
      // @ts-ignore
      dynamicConfig?.SENTRY_RELEASE ??
      import.meta.env.VITE_SENTRY_RELEASE ??
      undefined,
  },
}
export default config
