import { createRouter, createWebHistory } from "vue-router"

import LoginRequired from "@/layouts/LoginRequired.vue"
import NothingView from "@/views/NothingView.vue"
import LinkView from "@/views/LinkView.vue"

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/_internal_login_UNUSED",
      component: LoginRequired,
      children: [
        {
          path: "/link/:linkSlug",
          name: "link",
          component: LinkView,
          props: (route) => ({ linkSlug: route.params.linkSlug }),
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      component: NothingView,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash.length < 16) {
      return {
        el: to.hash,
        behavior: "smooth",
        top: 150,
      }
    }
  },
})

export default router
