<template>
  <v-app>
    <v-main>
      <div
        style="
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
          z-index: 2;
        "
      >
        <v-card :style="{ 'max-width': '30em' }" outlined>
          <v-card-title class="bg-primary">Link benötigt</v-card-title>
          <v-card-text
            ><p class="mt-3">
              Sie benötigen einen Zugriffs-Link. Dieser sieht etwa so aus:
              <samp class="text-mono text-primary"
                >{{ baseUrl }}link/&hellip;</samp
              >
            </p></v-card-text
          >
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<script lang="ts" setup>
const baseUrl = new URL("/", window.location.href)
</script>
